import React from "react"
import SubmitButton from "../../utils/button/submit"
import useForm from "../../../hooks/useForm"
import FormSuccess from "../../utils/form/success"
import FormError from "../../utils/form/error"

const MentorshipPartnershipForm = () => {
  const { onSubmit, isLoading, isError, isSuccess, errorMessage } = useForm();

  return (
    <div>
      <form
        name="mentorship_partnership"
        method="post"
        data-netlify="true"
        data-netlify-honeypot="bot-field"
        onSubmit={onSubmit}
      >
        <input type="hidden" name="form-name" value="mentorship_partnership" />
        <div className="form-wrapper">
          {/* Full Name */}
          <div className="form-group">
            <input
              type="text"
              required="required"
              name="fullname"
              placeholder="Full Name"
            />
          </div>

          {/* Email Address */}
          <div className="form-group">
            <input
              required="required"
              type="email"
              name="emailAddress"
              placeholder="Email Address"
            />
          </div>

          {/* Company/Team */}
          <div className="form-group">
            <input
              type="text"
              required="required"
              name="companyTeam"
              placeholder="Company/Team"
            />
          </div>

          {/* How would you like to partner with us?*/}
          <div className="form-group">
            <input
              type="text"
              required="required"
              name="message"
              placeholder="How would you like to partner with us?"
            />
          </div>
          <div/>
          <div className="form-group">
            <SubmitButton disabled={isLoading}/>
          </div>
        </div>
      </form>
      <div>
        {isSuccess && <FormSuccess message="Message has been delivered succcesfully"/>}
        {isError && <FormError message={errorMessage}/>}
      </div>
    </div>
  )
}

export default MentorshipPartnershipForm
