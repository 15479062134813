import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import PageHeading from "../../utils/page-heading"

const MentorshipIntro = () => {
  return (
    <>
      <StaticImage
        src="../../../images/MentorshipPage.jpg"
        placeholder="blurred"
        className="w-full"
        alt='Mentorship preview page'
      />

      <PageHeading text="Bridging the experience gap in the African design industry." />
      <p>
        Starting out as a designer can be quite tedious, especially in the African space where there
        isn’t a lot of affordable formal training. Also, the significant gap between the younger designers
        and more experienced designers does not help.  As part of efforts to bridge this gap, we
        launched a mentorship program to connect budding talents with more experienced hands in
        the space.
      </p>

      <p>
        The DearDesigner Mentorship program is designed to help young and
        upcoming designers stand on the shoulder of design giants through a
        moderated mentorship with more experienced designers from across Africa.
      </p>
    </>
  )
}

MentorshipIntro.propTypes = {}

export default MentorshipIntro
