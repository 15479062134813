import React from "react"
import PagesLayout from "../../components/layout/pages"
import {
  MentorshipIntro,
  MentorshipHowItWorks,
  MentorshipPartner,
  MentorshipEditions,
  MentorshipPartnershipForm,
} from "../../components/pages/mentorship"

const MentorshipLandingPage = () => {
  return (
    <PagesLayout title="Mentorship">
      <MentorshipIntro />
      <MentorshipHowItWorks />
      <MentorshipEditions />
      <MentorshipPartner />
      <MentorshipPartnershipForm />
    </PagesLayout>
  )
}

export default MentorshipLandingPage
