import React from "react"

const CopyIcon = ({...props}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 64 64"
      aria-labelledby="title"
      aria-describedby="desc"
      role="img"
      width={16}
      height={16}
      {...props}
    >
      <title>Copy</title>
      <path
        data-name="layer2"
        fill="currentColor" d="M14 14h50v50H14z"></path>
      <path
        data-name="layer1"
        fill="currentColor"
        d="M50 0H0v50h10V10h40V0z"
      ></path>
    </svg>
  )
}

export default CopyIcon