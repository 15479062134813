import React from "react"
import HyperLink from "../../../utils/HyperLink"

const MentorshipEditionPreview = ({ title, description, link }) => {
  return (
    <section className="even:md:bg-dd-black-20 even:md:mentorship-edition-preview-even even:md:px-16 even:md:py-10 e ">
      <h3 className="md:h3 h4">{title}</h3>
      <p>{description}</p>

      <p>{link && <HyperLink text="Read More" url={link} />}</p>
    </section>
  )
}

export default MentorshipEditionPreview
