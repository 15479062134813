import React from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"
import ctl from "@netlify/classnames-template-literals"

const MenuNav = ({
  heading = {},
  content,
  wrapperStyle,
  links = [],
  sidebar,
}) => {
  const heading_style = ctl(`
  text-p14 
  font-bold 
  tracking-widest 
  uppercase 
  ${!sidebar && "mb-6"}
  ${heading.style}
  `)

  const menu_list_style = ctl(`
  sm:block 
  hidden 
  -mt-2`)

  const showLinks = links.map((link, index) => (
    <li className="text-dd-black-60 leading-loose" key={index}>
      <Link to={`/${link.slug}`} key={index} className="text-dd-black-60 ">
        {link.name}
      </Link>
    </li>
  ))
  return (
    <div className={wrapperStyle}>
      {heading.text && (
        <h3 className={heading_style}>
          {heading.url ? <Link to={heading.url}>{heading.text}</Link> : heading.text}
        </h3>
      )}
      <div>
        {content}
        <ol className={menu_list_style}>{showLinks}</ol>
      </div>
    </div>
  )
}

MenuNav.propTypes = {
  heading: PropTypes.object,
}

export default MenuNav
