import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import MentorshipEditionPreview from "../../mentorship/editions/preview"

const MentorshipEditions = () => {
  const editionsQuery = useStaticQuery(graphql`
    {
      allWpMentorshipEdition(sort: { fields: name, order: ASC }) {
        edges {
          node {
            name
            uri
            slug
            description
            customFields {
              status
            }
          }
        }
      }
    }
  `)

  const allEditions = editionsQuery.allWpMentorshipEdition.edges

  const showEditions = allEditions.map((theEdition, index) => {
    const edition = theEdition.node
    const TITLE = `DD Mentorship ${edition.name}`
    const DESCIRPTION = edition.description

    let LINK = ""
    if (edition.customFields.status === "done") {
      LINK = `/mentorship/${edition.slug}`
    }

    return (
      <MentorshipEditionPreview
        key={index}
        title={TITLE}
        description={DESCIRPTION}
        link={LINK}
      />
    )
  })
  return (
    <section className="my-24 grid grid-cols-1 gap-14">{showEditions}</section>
  )
}

export default MentorshipEditions
