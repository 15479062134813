import React from "react"
import Layout from ".."
import SidebarNav from "./sidebar-nav"
import SidebarRight from "./sidebar-right"

const PagesLayout = ({ children, whiteBackground = false, disablePadding, className, ...props }) => {
  return (
    <Layout {...props}>
      <section className="wrapper grid lg:grid-cols-[1.13fr,5.06fr,1fr] gap-x-6">
        <SidebarNav />

        <section className={`${!whiteBackground && 'md:bg-dd-black-10'} ${className} ${disablePadding ? '' : 'md:p-16 md:pb-24'} mb-24`}>
          {children}
        </section>
        <SidebarRight />
      </section>
    </Layout>
  )
}

PagesLayout.propTypes = {}

export default PagesLayout
