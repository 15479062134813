import React from "react"

const Lists = ({ items, className, listClassName = '' }) => {
  const showItems = items.map((item, index) => (
    <li key={index} className={`mb-4 ${className}`}>
      {item}
    </li>
  ))
  return <ol className={`pl-6 list-disc ${listClassName}`}>{showItems}</ol>
}

export default Lists
