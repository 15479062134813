import React from "react"
import ArrowRightIcon from "../../../icons/arrow-right"

const SubmitButton = ({...props}) => {
  return (
    <button type="submit" {...props} className='text-dd-black-70 disabled:text-dd-black-40'>
      <ArrowRightIcon />
    </button>
  )
}

export default SubmitButton
