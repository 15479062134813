import React from "react"

const TitleText = ({ title, text }) => {
  return (
    <div className="md:mt-16 mt-10">
      <h4 className="md:text-2xl text-p16 text-dd-black-60">{title}</h4>
      <p className="mt-4">{text}</p>
    </div>
  )
}

export default TitleText
