import React from "react"
import TitleText from "../about-us/title-text"
import Lists from "../../utils/lists"

const MentorshipHowItWorks = () => {
  const steps = [
    "Interested mentees are required to go through a carefully-monitored application process. ",
    "Successful mentees would be matched with appropriate mentors in their preferred areas of practice.",
    "Each mentorship program officially last for a specified duration. ",
    "Mentors are required to dedicate some time per week to mentees. This time can be used for a number of tasks including project reviews, conversations, and so on.",
    "Relationships between mentors and mentees will be treated solely as professional ones.",
  ]

  return (
    <>
      <TitleText title="How it works" />
      <Lists className='md:!mb-9' items={steps} />
    </>
  )
}

export default MentorshipHowItWorks
