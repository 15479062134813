import React from "react"

const MentorshipPartner = () => {
  return (
    <section className="mt-24">
      <h3 className="md:h3 h4">Partner with/Support Us</h3>

      <p>
        As a non-profit organization, our greatest strength lies in the support of the individuals and the brands that partner with us to execute our projects and nurture our innovations.
      </p>
      <p>
        Looking to partner with us on the DearDesigner Mentorship program, reach out to us using the form below.
      </p>
    </section>
  )
}

export default MentorshipPartner
