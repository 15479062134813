import React from "react"
import JoinOurCommunity from "../../utils/join-our-community"
import ctl from "@netlify/classnames-template-literals"
import MenuNav from "../menu/nav"
import { EmailIcon, FacebookIcon, LinkedinIcon, TwitterIcon } from "../../../icons/social"
import { copyTextToClipboard } from "../../../utils/copy-to-clipboard"
import CopyIcon from "../../../icons/copy"
import "./sidebar-right.css"

const getHref = () => {
  if(typeof window !== "undefined"){
    return window?.location?.href
  }

  return ""
}

const social_media = [
  {
    icon: <TwitterIcon />,
    link: `https://twitter.com/intent/tweet?text=`,
  },
  {
    icon: <EmailIcon />,
    link: `mailto:hello@deardesigner.xyz?body=`,
  },
  {
    icon: <LinkedinIcon />,
    link: `http://www.linkedin.com/shareArticle?mini=true&url=`,
  },
  {
    icon: <FacebookIcon />,
    link: `https://www.facebook.com/sharer/sharer.php?u=`,
  },
]

const showSocialMediaIcons = social_media.map((social, index) => {
  const onClick = () => {
    window.open(`${social.link}${window.location.href}`, '_blank')
  }

  return (
    (
      <span
        onClick={onClick}
        rel="noreferrer"
        className="inline-flex social-media-link cursor-pointer"
        key={index}
      >
        {social.icon}
      </span>
    )
  )
})

const SidebarRight = () => {
  const social_media_icons_container_style = ctl(`
    grid gap-x-3 gap-y-3
    grid-cols-[24px,24px,16px,24px] 
    text-center 
    items-center
    place-items-start
    `)

  const copy_button_style = ctl(`
    border-none bg-transparent 
    text-dd-black-60 text-sm mt-4 hover:text-dd-black-80
    flex items-center space-x-2
  `)

  const onClickCopyLink = () => {
    copyTextToClipboard(window.location.href)
  }

  return (
    <div className="lg:block hidden relative flex-shrink-0 lg:mt-12 p">
      <section className="sticky top-4">
        <JoinOurCommunity location_sidebar />

        <div className="mt-12">
          <MenuNav
            heading={{text: "Share"}}
            content={
              <>
                <div className={social_media_icons_container_style}>
                  {showSocialMediaIcons}
                </div>
                <button onClick={onClickCopyLink} className={copy_button_style}>
                  <CopyIcon/>
                  <span>
                    Copy page link
                  </span>
                </button>
              </>
            }
            sidebar
          />
        </div>
      </section>
    </div>
  )
}

SidebarRight.propTypes = {}

export default SidebarRight
