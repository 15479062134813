import React from "react"
import { MenuItemAboutUs, MenuItemBlog, MenuItemDonate, MenuItemMentorship } from "../menu/items"

const SidebarNav = () => {
  return (
    <nav className="lg:block hidden relative flex-shrink-0">
      <ul className="sticky top-4 grid gap-y-6">
        <MenuItemBlog />
        <MenuItemMentorship />
        {/*<MenuItemIntitiatives />*/}
        <MenuItemAboutUs />
        <MenuItemDonate />
      </ul>
    </nav>
  )
}

SidebarNav.propTypes = {}

export default SidebarNav
